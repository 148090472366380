import axios from "axios";
const baseUrl = process.env.VUE_APP_BACKEND_URL;

export default {
  state: {
    instagram_token: null,
  },
  mutations: {
    SET_IG_TOKEN(state, value) {
      state.instagram_token = value;
    },
  },
  actions: {
    getIgToken({ commit }) {
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}igt`)
          .then(({ data }) => {
            commit("SET_IG_TOKEN", data);
            resolve();
          })
          .catch(this.$errorHandler);
      });
    },
  },
  getters: {
    instagram_token: (state) => state.instagram_token,
  },
};
