<template>
  <div
    id="card-product-home-comp"
    class="scroll-snap anim-prod-card-wrapper anim-wrapper"
    v-if="item"
    :class="[item.side == 'left' ? 'side-left' : 'side-right']"
  >
    <div class="card-text">
      <div class="card-text-cont">
        <router-link :to="item.url"
          ><h3 class="mb-3 mb-md-3">
            <span
              class="anim-prod-card fsm-5"
              :class="[item.side == 'left' ? 'side-left' : 'side-right']"
              >{{ item.name }}</span
            >
          </h3></router-link
        >
        <p class="mb-4 fsm-3">{{ item.description }}</p>
        <router-link :to="item.url" class="mos-btn"> Ver Entregas </router-link>
      </div>
    </div>
    <div
      class="card-img"
      :class="[item.side == 'left' ? 'anim-left' : 'anim-right']"
      :style="{ 'background-image': 'url(' + item.image_path + ')' }"
    ></div>
  </div>
</template>
<script>
import mobileChecker from '@/mixins/mobileChecker'

export default {
  mixins: [mobileChecker],
  props: ['item']
}
</script>
<style scoped lang="scss">
#card-product-home-comp {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: calc(100vh - var(--header-height));
  overflow: hidden;
  max-height: 1500px;
  max-width: 2200px;
  margin: auto;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    margin-bottom: 2rem;
    padding: 0;
    height: unset;
  }
  @media (min-width: 768px) {
    flex-direction: column-reverse;
    margin-bottom: 2rem;
    padding: 0;
    height: unset;
  }
  @media (min-width: 992px) {
    flex-direction: row;
    margin: auto;
    height: calc(100vh - var(--header-height));
  }

  &.side-left {
    flex-direction: row-reverse;

    .card-text {
      align-items: center;

      .card-text-cont {
        align-items: flex-end;
      }

      .title-image {
        height: 100px;
        right: -115px;
        bottom: -10px;
      }

      h3,
      a,
      p {
        text-align: right;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column-reverse;
    }
    @media (min-width: 768px) {
      flex-direction: column-reverse;
    }
    @media (min-width: 992px) {
      flex-direction: row-reverse;
    }
  }

  &.side-right {
    .card-text {
      align-items: center;

      .card-text-cont {
        align-items: flex-start;
      }

      .title-image {
        height: 90px;
        left: -95px;
        bottom: -5px;
      }

      h3,
      a,
      p {
        text-align: left;
      }
    }
  }

  .card-text {
    width: 50%;
    display: grid;
    place-items: center;
    height: 100%;

    @media (max-width: 767px) {
      width: 100%;
      height: unset;
      padding: 1.5rem 0;
    }
    @media (min-width: 768px) {
      width: 100%;
      height: unset;
      padding: 1.5rem 0;
    }
    @media (min-width: 992px) {
      height: 100%;
      padding: 1rem;
      width: 50%;
    }

    .card-text-cont {
      width: 70%;
      display: flex;
      flex-direction: column;

      @media (max-width: 767px) {
        width: 100%;
        padding: 1rem 5%;
      }
      @media (min-width: 768px) {
        width: 100%;
        padding: 1rem 5%;
      }
      @media (min-width: 992px) {
        width: 70%;
        padding: 0;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 31px;
      text-transform: uppercase;
      position: relative;
      z-index: 999;

      span {
        position: relative;
        z-index: 9999;

        &::before {
          content: '';
          position: absolute;
          width: calc(100% + 2rem);
          // transform: scaleX(0);
          height: 15%;
          opacity: 0.9;
          bottom: -4px;
          background-color: $color-secondary;
          transition: transform 0.25s ease-in;
          transition-delay: 0.5s;
          z-index: -1;
        }

        &.side-left {
          &::before {
            border-radius: 0 0 0 50%;
            right: -10px;
            transform-origin: bottom right;
          }
        }

        &.side-right {
          &::before {
            border-radius: 0 0 50% 0;
            left: -10px;
            transform-origin: bottom left;
          }
        }
      }

      .title-image {
        position: absolute;
        width: auto;
        z-index: 50;
      }

      @media (max-width: 767px) {
        font-size: 26px;
      }
    }

    p {
      opacity: 0.85;
    }

    > a {
      text-decoration: none;
      width: fit-content;
    }

    .mos-btn {
      background-color: $color-black;
      color: $color-white;
      padding: 0.5rem 2rem;
      font-weight: 600;

      &:hover {
        background-color: $color-secondary;
      }
    }
  }
  .card-img {
    width: 50%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 1.2s ease-out;
    position: relative;

    &:hover {
      opacity: 0.8;
    }

    @media (max-width: 767px) {
      width: 100%;
      height: 25rem;
    }
    @media (min-width: 768px) {
      width: 100%;
      height: 35rem;
    }
    @media (min-width: 992px) {
      width: 50%;
      height: 100%;
    }
  }
}
</style>
