<template>
  <div id="home-testimonials" class="scroll-snap">
    <div
      class="sc-bg"
      style="background-image: url('/static/images/testimonials/testimonials1.webp')"
    >
      <div class="sc-bg-overlay"></div>
      <div class="sc-main">
        <div class="sc-main-bg"></div>
        <section class="sc-left">
          <div class="sc-icon-cont"><i class="fa-solid fa-quote-left"></i></div>
          <div class="sc-left-txt">
            <h4 class="fsm-4">Testimonios de nuestros clientes</h4>
            <p class="fsm-3">
              Abocados en satisfacer los estándares máximos de calidad y el servicio que todos
              ustedes merecen.
            </p>
          </div>
        </section>
        <section class="sc-right">
          <div class="testimonials-wrapper">
            <div class="testimonials-carrousel">
              <div class="testimonial_card" style="animation-delay: -45s">
                <img src="/static/images/testimonials/testimonios-clientes1.webp" alt="" />
              </div>
              <div class="testimonial_card" style="animation-delay: -41s">
                <img src="/static/images/testimonials/testimonios-clientes2.webp" alt="" />
              </div>
              <div class="testimonial_card" style="animation-delay: -37s">
                <img src="/static/images/testimonials/testimonios-clientes3.webp" alt="" />
              </div>
              <div class="testimonial_card" style="animation-delay: -33s">
                <img src="/static/images/testimonials/testimonios-clientes4.webp" alt="" />
              </div>
              <div class="testimonial_card" style="animation-delay: -29s">
                <img src="/static/images/testimonials/testimonios-clientes5.webp" alt="" />
              </div>
              <div class="testimonial_card" style="animation-delay: -25s">
                <img src="/static/images/testimonials/testimonios-clientes6.webp" alt="" />
              </div>
              <div class="testimonial_card" style="animation-delay: -21s">
                <img src="/static/images/testimonials/testimonios-clientes7.webp" alt="" />
              </div>
              <div class="testimonial_card" style="animation-delay: -17s">
                <img src="/static/images/testimonials/testimonios-clientes8.webp" alt="" />
              </div>
              <div class="testimonial_card" style="animation-delay: -13s">
                <img src="/static/images/testimonials/testimonios-clientes9.webp" alt="" />
              </div>
              <div class="testimonial_card" style="animation-delay: -9s">
                <img src="/static/images/testimonials/testimonios-clientes10.webp" alt="" />
              </div>
              <div class="testimonial_card" style="animation-delay: -5s">
                <img src="/static/images/testimonials/testimonios-clientes11.webp" alt="" />
              </div>
              <div class="testimonial_card" style="animation-delay: -2s">
                <img src="/static/images/testimonials/testimonios-clientes12.webp" alt="" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style scoped lang="scss">
#home-testimonials {
  height: calc(100vh - var(--header-height));
  width: 100%;
  max-height: 1500px;
  max-width: 2200px;
  margin: auto;
  overflow: hidden;

  @media (max-width: 767px) {
    height: unset;
    max-height: unset;
  }
  @media (min-width: 768px) {
    height: unset;
    max-height: unset;
  }
  @media (min-width: 992px) {
    height: calc(100vh - var(--header-height));
    max-height: 1500px;
  }

  .sc-bg {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    display: flex;
    align-items: center;
    padding: 2rem 10% 2rem 10%;

    @media (max-width: 767px) {
      padding: 5rem 5% 3rem 5%;
      background-attachment: unset;
    }

    .sc-bg-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba($color: $color-black, $alpha: 0.4);
    }

    .sc-main {
      display: flex;
      align-items: center;
      height: clamp(75%, 1vw, 65%);
      width: 100%;
      position: relative;

      @media (max-width: 767px) {
        flex-direction: column;
        height: unset;
      }

      .sc-main-bg {
        position: absolute;
        height: 100%;
        width: 90%;
        background-color: $color-beige;
        z-index: 888;

        @media (max-width: 767px) {
          width: 100%;
        }
      }

      section.sc-left {
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 999;
        padding: 4rem 5% 4rem 5%;

        @media (max-width: 767px) {
          width: 100%;
          height: unset;
          padding: 4rem 5% 1rem 5%;
        }

        .sc-icon-cont {
          position: absolute;
          width: clamp(140px, 1vw, 180px);
          height: clamp(140px, 1vw, 180px);
          background-color: $color-white;
          border-radius: 50%;
          top: clamp(-70px, 1vw, -90px);
          left: 10%;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 767px) {
            width: clamp(100px, 1vw, 110px);
            height: clamp(100px, 1vw, 110px);
            top: clamp(-50px, 1vw, -55px);
            left: 5%;
          }

          i {
            font-size: clamp(70px, 1vw, 90px);
            color: $color-black;
            opacity: 0.85;

            @media (max-width: 767px) {
              font-size: clamp(40px, 1vw, 50px);
            }
          }
        }

        .sc-left-txt {
          h4 {
            margin-bottom: clamp(1rem, 1vw, 1.5rem);
          }
          p {
            opacity: 0.85;
          }
        }
      }
      section.sc-right {
        position: relative;
        z-index: 999;
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
          width: 100%;
          height: 20rem;
        }

        .testimonials-wrapper {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 4rem;

          @media (max-width: 767px) {
            padding-left: 0;
          }

          .testimonials-carrousel {
            -webkit-perspective: 700px;
            -moz-perspective: 700px;
            width: 100%;
            height: 50%;
            position: relative;
            margin: 0 auto;
            .testimonial_card {
              width: 250px;
              height: 100%;
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              left: 0;
              right: 0;
              margin: 0 auto;
              -webkit-animation-name: efeitoCarrosel;
              -moz-animation-name: efeitoCarrosel;
              -webkit-animation-duration: 45s;
              -moz-animation-duration: 45s;
              -webkit-animation-iteration-count: infinite;
              -moz-animation-iteration-count: infinite;
              -webkit-animation-timing-function: linear;
              -moz-animation-timing-function: linear;
              transition: opacity 0.5s ease-in-out;
              opacity: 1;
              img {
                width: 100%;
                height: auto;
                border-radius: 0.5rem;
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
              }
              @media (max-width: 767px) {
                width: 150px;
              }
              @media (min-width: 768px) {
              }
              @media (min-width: 992px) {
                width: 140px;
              }
              @media (min-width: 1200px) {
                width: 180px;
              }
              @media (min-width: 1400px) {
                width: 210px;
              }
              @media (min-width: 1460px) {
                width: 230px;
              }
              @media (min-width: 1660px) {
                width: 250px;
              }
            }
          }

          @-webkit-keyframes efeitoCarrosel {
            from {
              -webkit-transform: rotateY(360deg) translateZ(230px) rotateY(-360deg);
              -moz-transform: rotateY(360deg) translateZ(230px) rotateY(-360deg);
              z-index: 10;
              opacity: 1;
            }
            15% {
              opacity: 1;
            }
            50% {
              z-index: -10;
              opacity: 0;
            }
            85% {
              opacity: 1;
            }
            to {
              -webkit-transform: rotateY(0deg) translateZ(230px) rotateY(0deg);
              -moz-transform: rotateY(0deg) translateZ(230px) rotateY(0deg);
              z-index: 10;
              opacity: 1;
            }
          }

          @-moz-keyframes efeitoCarrosel {
            from {
              -moz-transform: rotateY(360deg) translateZ(230px) rotateY(-360deg);
              z-index: 10;
              opacity: 1;
            }
            15% {
              opacity: 1;
            }
            50% {
              z-index: -10;
              opacity: 0;
            }
            85% {
              opacity: 1;
            }
            to {
              -moz-transform: rotateY(0deg) translateZ(230px) rotateY(0deg);
              z-index: 10;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
</style>
