<template>
  <div id="article-data-comp">
    <section class="sc-main">
      <h3 class="mb-4 fsm-5">{{ item.name }}</h3>
      <p class="mb-4 fsm-3">{{ item.description }}</p>
      <div class="mos-btn mos-btn-sm mos-btn-alterh" @click="goWhatsapp(item.wplink)">
        <i class="fa-brands fa-whatsapp"></i>
        Obtener cotización
      </div>
    </section>
  </div>
</template>
<script>
export default {
  props: ['item'],
  methods: {
    goWhatsapp(wplink) {
      if (window.fbq) {
        fbq('track', 'Contact')
      }
      window.open(wplink, '_blank')
    }
  }
}
</script>
<style scoped lang="scss">
#article-data-comp {
  width: 32%;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 7%;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 992px) {
    width: 32%;
  }

  .sc-main {
    width: 100%;
    padding-left: 2rem;

    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }
    @media (min-width: 768px) {
      padding-top: 3rem;
    }
    @media (min-width: 992px) {
      padding-top: 0;
    }
    h3 {
      font-weight: 600;
      text-transform: uppercase;
    }
    p {
      opacity: 0.85;
    }
    .mos-btn {
      @media (max-width: 767px) {
        width: 100%;
        justify-content: center;
        padding: 0.75rem;
      }
    }
  }
}
</style>
