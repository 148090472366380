<template>
  <div class="loading-overlay" v-if="site_loading">
    <LoaderComp />
  </div>
  <!-- <TopbarMobile v-if="isMobile || isTablet" /> -->
  <TopbarTst />
  <!-- <TopbarMenu /> -->
  <div class="content" v-if="!site_loading">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import TopbarTst from "Components/topbar/TopbarTst.vue";
// import TopbarMobile from "Components/topbar/topbarmobile/TopbarMobile.vue";
import mobileChecker from "@/mixins/mobileChecker";
import useSlideAnimations from "@/composables/slideAnimations.js";
import LoaderComp from "Components/global/misc/LoaderComp.vue";

export default {
  mixins: [mobileChecker],
  components: {
    TopbarTst,
    // TopbarMobile,
    LoaderComp,
  },
  data() {
    return {
      site_loading: false,
    };
  },
  created() {
    setTimeout(() => {
      document.querySelector("#app").addEventListener("scroll", () => {
        useSlideAnimations();
      });
    }, 1600);
  },
  mounted() {
    this.site_loading = true;
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        setTimeout(() => (this.site_loading = false), 1400);
      }
    };
  },
};
</script>

<style lang="scss">
#app {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-padding-top: var(--header-height);
  position: relative;
  scroll-behavior: smooth;

  @media (max-width: 767px) {
    scroll-snap-type: unset;
  }
  @media (min-width: 768px) {
    scroll-snap-type: unset;
  }
  @media (min-width: 992px) {
    scroll-snap-type: y mandatory;
  }
}
.content {
  min-height: 100vh;
  background-color: $color-white;
}
.loading-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: $color-beige;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
