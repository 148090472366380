<template>
  <div id="home-famcli">
    <div class="sc-main scroll-snap">
      <div class="sc-container">
        <div class="sc-wrap">
          <div class="sc-cont">
            <swiper
              :slidesPerView="isMobile ? 1.6 : 5.9"
              :spaceBetween="isMobile ? 15 : 20"
              :navigation="false"
              :pagination="false"
              :loop="true"
              :modules="modules"
              centeredSlides="true"
              class="mySwiper2"
            >
              <swiper-slide v-for="item in clientPics" :key="item.id">
                <div class="s-item">
                  <a :href="item.link" target="_blank"
                    ><i class="fa-solid fa-arrow-up-right-from-square"></i>Ver</a
                  >
                  <img :src="item.image" />
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="sc-text">
            <h4 class="fsm-4 font-bold">Cada vez son más los hogares que confían en nosotros</h4>
            <p class="sc-subtitle fsm-3">
              Ellos confiaron en nosotros y ahora disfrutan del confort de nuestros sillones.
              <br />
              ¡Gracias por se parte de nuestra historia!
            </p>
          </div>
          <div class="sc-cont">
            <swiper
              :slidesPerView="isMobile ? 1.6 : 5.9"
              :spaceBetween="isMobile ? 15 : 15"
              :navigation="false"
              :pagination="false"
              :loop="true"
              :modules="modules"
              centeredSlides="true"
              class="mySwiper3"
            >
              <swiper-slide v-for="item in historiasPics" :key="item.id">
                <div class="s-item">
                  <img :src="item.image" />
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Famclients from '@/data/famclients.json'
import Clienthistorias from '@/data/clienthistorias.json'
import mobileChecker from '@/mixins/mobileChecker'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/thumbs'
// import required modules
import { FreeMode, Navigation, Pagination, Thumbs } from 'swiper'

export default {
  mixins: [mobileChecker],
  data() {
    return {
      clientPics: Famclients.data,
      historiasPics: Clienthistorias.data
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper
    }
  },
  setup() {
    return {
      modules: [FreeMode, Pagination, Navigation, Thumbs]
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'swiper/css/pagination';

#home-famcli {
  width: 100%;
  max-width: 2200px;
  margin: auto;
  max-height: 1500px;

  @media (max-width: 767px) {
    max-height: unset;
  }
  @media (min-width: 768px) {
    max-height: unset;
  }
  @media (min-width: 992px) {
    max-height: 1500px;
  }

  .sc-main {
    height: calc(100vh - var(--header-height));
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding: 2rem 0;

    @media (max-width: 767px) {
      height: unset;
    }
    @media (min-width: 768px) {
      height: unset;
    }
    @media (min-width: 992px) {
      height: calc(100vh - var(--header-height));
    }
  }

  .sc-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;

    @media (max-width: 576px) {
    }

    .sc-wrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
    }

    .sc-text {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding: 1rem 0;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 260%;
        background-color: #fef6e9;
      }

      h4 {
        font-size: 20px;
        margin-bottom: 0.3rem;
        font-weight: bold;
        position: relative;
        z-index: 1;
        text-wrap: balance;

        @media (max-width: 576px) {
          padding: 0 1rem;
        }
      }
    }
    .sc-cont {
      width: 100%;
      overflow: hidden;
    }
  }

  .sc-subtitle {
    max-width: 600px;
    opacity: 0.85;
    text-wrap: balance;

    @media (max-width: 576px) {
      padding: 0 1rem;
    }
  }

  .swiper {
    width: 850px;
    height: auto;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      black 20%,
      black 80%,
      rgba(0, 0, 0, 0) 100%
    );
    -webkit-mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      black 20%,
      black 80%,
      rgba(0, 0, 0, 0) 100%
    );

    @media (max-width: 576px) {
      width: 100%;
    }
    @media (min-width: 577px) {
      width: 100%;
    }
    @media (min-width: 768px) {
      width: 100%;
    }
    @media (min-width: 992px) {
      width: 1200px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .s-item {
      height: 100%;
      aspect-ratio: 5/6.5;
      position: relative;
      border-radius: 0.8rem;
      overflow: hidden;

      @media (max-width: 576px) {
        border-radius: 1rem;
      }

      a {
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 2rem;
        background-color: $color-primary;
        color: $color-white;
        height: 23px;
        font-size: 13px;
        transition: all 0.3s ease-in-out;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 0.4rem;
        padding: 0 0.5rem;

        &:hover {
          background-color: darken($color-primary, 10%);
        }
      }
    }

    &.mySwiper3 {
      .s-item {
        aspect-ratio: 5/8;
      }
    }

    // .swiper-button-prev {
    //   left: unset !important;
    //   right: 180px !important;
    //   top: -20px;
    // }
  }
}
</style>
