<template>
  <div id="features-test" class="scroll-snap">
    <div class="sc-main scroll-snap anim-wrapper">
      <img class="test-img" src="/static/images/aboutus/aboutus-img3.webp" />
      <div class="bg-test">
        <div class="features-test-wrapper">
          <div class="row">
            <div class="col-12 col-md-6" v-for="feature in features" :key="feature.id">
              <div class="features-test-box anim-opacity" :class="`anim-delay-${feature.id}`">
                <div class="icon-box">
                  <i :class="feature.icon"></i>
                </div>
                <h4 class="fsm-4">{{ feature.title }}</h4>
                <p class="fsm-3">{{ feature.subtitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import features from '@/data/features.json'

export default {
  data() {
    return {
      features: features.data
    }
  }
}
</script>
<style lang="scss" scoped>
#features-test {
  height: calc(100vh - var(--header-height));
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 2200px;
  margin: auto;
  max-height: 1500px;
  position: relative;

  .sc-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
    overflow: hidden;
  }

  .test-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    position: absolute;
    left: 0;
    top: 0;

    filter: blur(5px) brightness(1.1) contrast(0.9) sepia(0.8);
  }

  .anim-delay-1 {
    transition-delay: 0.2s;
  }
  .anim-delay-2 {
    transition-delay: 0.6s;
  }
  .anim-delay-3 {
    transition-delay: 1s;
  }
  .anim-delay-4 {
    transition-delay: 1.4s;
  }

  .bg-test {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    background: rgba($color: $color-beige, $alpha: 0.65);
  }

  .features-test-wrapper {
    width: 70%;
    padding: 2rem 0;

    @media (max-width: 576px) {
      width: 90%;
    }
    @media (min-width: 992px) {
      min-width: 900px;
      max-width: 1200px;
    }
  }

  .features-test-box {
    margin-bottom: 2rem;
    padding: 0.5rem 1.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 576px) {
      padding-left: 8px;
      align-items: flex-start;
      text-align: left;
    }

    p {
      font-weight: 600;
      opacity: 0.85;
    }

    .icon-box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 70px;
      border-radius: 0.85rem;
      background-color: $color-primary;
      margin-bottom: 0.75rem;

      @media (max-width: 576px) {
        height: 50px;
        width: 65px;
        border-radius: 0.75rem;
      }

      i {
        font-size: 29px;
        color: $color-black;
        opacity: 0.85;

        @media (max-width: 576px) {
          font-size: 24px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    height: unset;
  }
  @media (min-width: 768px) {
    height: unset;
  }
  @media (min-width: 992px) {
    height: calc(100vh - var(--header-height));
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1460px) {
  }

  .square {
    width: 50px;
    height: 50px;
    background-color: red;
  }
}
</style>
